import {
  Breadcrumbs,
  Grid,
  GridSize,
  makeStyles,
  Typography,
} from '@material-ui/core'
import Container from '@material-ui/core/Container'
import classnames from 'classnames'
import Head from 'next/head'
import { NextRouter, useRouter } from 'next/router'
import React, { useEffect } from 'react'
import widont from 'widont'

import { IAsset, ILink, isAsset } from '../../lib/contentful/types'
import { useBackgroundImage } from '../ContentfulImage/background'
import { useIntercom } from '../Intercom'
import Link from '../Link'
import { useAnalytics } from '../Segment'
import classNames from 'classnames'

const SITE_TITLE = process.env.NEXT_PUBLIC_SITE_TITLE

const getCanonicalUrl = (router: NextRouter) => {
  const baseUrl =
    (process.env.VERCEL_URL && `https://${process.env.VERCEL_URL}`) ||
    process.env.NEXT_PUBLIC_BASE_URL

  const _pathSliceLength = Math.min.apply(Math, [
    router.asPath.indexOf('?') > 0
      ? router.asPath.indexOf('?')
      : router.asPath.length,
    router.asPath.indexOf('#') > 0
      ? router.asPath.indexOf('#')
      : router.asPath.length,
  ])
  return baseUrl + router.asPath.substring(0, _pathSliceLength)
}

type TPageVariant = 'wide' | 'medium' | 'narrow' | 'mini' | 'full'

export type TBreadcrumb = {
  href: string
  label: string
}

type PageProps = {
  title: string
  titleClass?: string
  pageTitle?: React.ReactNode
  banner?: IAsset | ILink<'Asset'> | string
  ogImage?: IAsset | ILink<'Asset'> | string
  description?: string
  showTitle?: boolean
  showPageTitle?: boolean
  centerTitle?: boolean
  breadcrumbs?: TBreadcrumb[]
  breadcrumbsBase?: TBreadcrumb
  variant?: TPageVariant
  statusCode?: number
}

function getGridWidths(variant: TPageVariant): {
  gridMd: GridSize
  gridLg: GridSize
  titleGridMd: GridSize
  titleGridLg: GridSize
} {
  let gridMd: GridSize = 12
  let gridLg: GridSize = 12
  switch (variant) {
    case 'mini':
      gridMd = 6
      gridLg = 4
      break
    case 'narrow':
      gridMd = 8
      gridLg = 6
      break
    case 'medium':
      gridMd = 9
      gridLg = 8
      break
    default:
    // do nothing
  }
  let titleGridMd: GridSize = gridMd
  let titleGridLg: GridSize = gridLg

  switch (variant) {
    case 'mini':
      titleGridMd = 12
      titleGridLg = 12
      break
    default:
    // do nothing
  }

  return { gridMd, gridLg, titleGridMd, titleGridLg }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme) => ({
  pageTitleHeader: {
    backgroundColor: theme.palette.primary.light,
    marginBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    borderBottom: `4px solid ${theme.palette.primary.dark}`,
  },
  pageTitle: {
    lineHeight: 1,
    color: theme.palette.primary.dark,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
  banner: {
    marginTop: -theme.spacing(1),
    height: '40vh',
  },
  marginDecorator: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    flexGrow: 1,
    '& .decorator-color': {
      marginTop: '0.75rem',
      height: theme.spacing(1),
      background: theme.palette.primary.dark,
    },
  },
  breadcrumbsWrapper: {},
  breadcrumbs: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    '& .MuiBreadcrumbs-ol': {
      flexWrap: 'nowrap',
      '& li:last-of-type': {
        flex: '1 1 auto',
        minWidth: 0,
      },
    },
    '& .MuiBreadcrumbs-li': {
      '& br': {
        // firefox doesn't like the content hack with BRs, so just hide the BR
        '@supports (-moz-appearance:none)': {
          display: 'none',
        },
        content: '" "',
        '&::after': {
          content: '" "',
        },
      },
      '& .MuiTypography-root': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
}))

const Page: React.FC<PageProps> = ({
  children,
  title = '',
  pageTitle,
  banner,
  ogImage = banner,
  description,
  variant = 'medium',
  showTitle = true,
  titleClass,
  showPageTitle = true,
  centerTitle,
  breadcrumbs,
  breadcrumbsBase,
  statusCode,
}) => {
  const classes = useStyles()
  const analytics = useAnalytics()
  const intercom = useIntercom()
  const bannerClassName = useBackgroundImage(banner)
  const canonicalUrl = getCanonicalUrl(useRouter())

  const ogImageUrl =
    typeof ogImage === 'string'
      ? ogImage
      : isAsset(ogImage)
      ? `${ogImage.fields.file.url}?w=1200&h=630&fit=fill`
      : undefined
  if (!pageTitle) pageTitle = title
  const titleTag = showTitle ? `${title} | ${SITE_TITLE}` : SITE_TITLE

  useEffect(() => {
    // Track page views
    if (analytics) analytics.page(title, { title: titleTag })
    if (intercom) intercom('update')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (statusCode && statusCode >= 400)
    return <Typography>Error: {statusCode}</Typography>

  const { gridMd, gridLg, titleGridMd, titleGridLg } = getGridWidths(variant)

  return (
    <>
      <Head>
        <title>{titleTag}</title>
        <meta property="og:title" content={titleTag}></meta>
        <meta property="og:type" content="website"></meta>
        <meta property="og:url" content={canonicalUrl}></meta>
        <meta property="og:site_name" content={SITE_TITLE} />
        <meta name="twitter:card" content="summary"></meta>
        {ogImageUrl && <meta property="og:image" content={ogImageUrl}></meta>}
        {description && (
          <>
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
          </>
        )}
      </Head>
      {bannerClassName && (
        <div className={classnames(bannerClassName, classes.banner)} />
      )}
      {(breadcrumbs || showPageTitle) && (
        <header className={classes.pageTitleHeader}>
          {(breadcrumbs || breadcrumbsBase) && (
            <Container fixed className={classes.breadcrumbsWrapper}>
              <Grid container justifyContent="center">
                <Grid item xs={12} md={titleGridMd} lg={titleGridLg}>
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={'»'}
                    className={classes.breadcrumbs}
                  >
                    {breadcrumbs &&
                      breadcrumbs.map(({ href, label }, index) => {
                        if (index < breadcrumbs.length - 1) {
                          return (
                            <Link key={href} href={href}>
                              {label}
                            </Link>
                          )
                        } else {
                          return <Typography key={href}>{label}</Typography>
                        }
                      })}
                    {breadcrumbsBase &&
                      !breadcrumbs && [
                        <Link key="base" href={breadcrumbsBase.href}>
                          {breadcrumbsBase.label}
                        </Link>,
                        <Typography key="page">{pageTitle}</Typography>,
                      ]}
                  </Breadcrumbs>
                </Grid>
              </Grid>
            </Container>
          )}
          {showPageTitle && (
            <Container fixed>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  md={centerTitle ? 12 : titleGridMd}
                  lg={centerTitle ? 12 : titleGridLg}
                >
                  <Typography
                    variant="h3"
                    component="h1"
                    align={centerTitle ? 'center' : 'left'}
                    className={classNames(classes.pageTitle, titleClass)}
                  >
                    {typeof pageTitle === 'string' &&
                    pageTitle.split(' ').length > 2
                      ? widont(pageTitle)
                      : pageTitle}
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          )}
        </header>
      )}
      {variant === 'full' ? (
        <div className="page-content">{children}</div>
      ) : (
        <Container fixed>
          {variant === 'wide' ? (
            <div className="page-content">{children}</div>
          ) : (
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} md={gridMd} lg={gridLg}>
                <div className="page-content">{children}</div>
              </Grid>
            </Grid>
          )}
        </Container>
      )}
    </>
  )
}

export default Page
