import { makeStyles, Theme } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'

const DEFAULT_ASPECT_RATIO = 16 / 9

const useEmbedStyles = makeStyles<Theme, { height?: string }>((theme) => ({
  embedResponsive: {
    margin: theme.spacing(4, 0),
    width: '100%',
    position: 'relative',
    display: 'block',
    height: 0,
    overflow: 'hidden',
    paddingBottom: ({ height }) =>
      height ? height : `${(1 / DEFAULT_ASPECT_RATIO) * 100}%`,
  },
  embedResponsiveItem: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    border: 0,
  },
}))

const ResponsiveEmbed: React.FC<{
  src?: string
  title?: string
  height?: string
  className?: string
}> = ({ src, title = '', height, className }) => {
  const classes = useEmbedStyles({ height })

  if (!src) return null

  const parsedSrc = src
    .replace('www.ted.com', 'embed.ted.com')
    .replace(/youtube.com\/watch.*v=([^&]+).*/, 'youtube.com/embed/$1')

  return (
    <div className={classNames(classes.embedResponsive, className)}>
      <embed
        src={parsedSrc}
        title={title}
        className={classes.embedResponsiveItem}
      />
    </div>
  )
}

export default ResponsiveEmbed
