import { makeStyles, Theme } from '@material-ui/core'
import classnames from 'classnames'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import behead from 'remark-behead'
import directive from 'remark-directive'
import footnotes from 'remark-footnotes'
import gfm from 'remark-gfm'
import slug from 'remark-slug'
import { PluggableList } from 'unified'

import htmlDirectives from './remark-plugins/html-directives'
import renderers, { simpleHeadingsRenderers } from './renderers'

const REMARK_PLUGINS: PluggableList = [
  gfm,
  [behead, { depth: 1 }],
  slug,
  [footnotes, { inlineNotes: true }],
  directive,
  htmlDirectives,
]

const REHYPE_PLUGINS: PluggableList = []

const useStyles = makeStyles<
  Theme,
  {
    preserveTopMargin: boolean
  }
>(() => ({
  root: {
    '&>:first-child': {
      marginTop: ({ preserveTopMargin }: { preserveTopMargin: boolean }) =>
        preserveTopMargin ? 'default' : 0,
    },
  },
}))

type MarkdownProps = {
  children: string
  TOC?: string
  className?: string
  preserveTopMargin?: boolean
  simpleHeadings?: boolean
}

const Markdown: React.FC<MarkdownProps> = ({
  children,
  className,
  preserveTopMargin = false,
  simpleHeadings = false,
}) => {
  const classes = useStyles({
    preserveTopMargin,
  })
  const plugins = [...REMARK_PLUGINS]

  return (
    <div className={classnames(classes.root, className)}>
      <ReactMarkdown
        components={simpleHeadings ? simpleHeadingsRenderers : renderers}
        remarkPlugins={plugins}
        rehypePlugins={REHYPE_PLUGINS}
      >
        {children}
      </ReactMarkdown>
    </div>
  )
}

export default Markdown
