import { ButtonProps, GridProps } from '@material-ui/core'
import { Properties } from 'hast'
import h from 'hastscript'
import { Plugin, Transformer } from 'unified'
import { Node } from 'unist'
import visit, { Visitor } from 'unist-util-visit'

type DirectiveNode = Node & {
  name: string
  attributes: Properties
}

/** Transformer to visit any directive elements */
const transformer: Transformer = (ast) => {
  visit(ast, ['textDirective', 'leafDirective', 'containerDirective'], visitor)
}

const allowedTags: Record<string, string[]> = {
  button: ['href', 'color', 'variant'] as (keyof ButtonProps)[],
  'grid-container': ['justify', 'spacing'] as (keyof GridProps)[],
  'grid-item': ['xs', 'sm', 'md', 'lg', 'xl'] as (keyof GridProps)[],
  embed: ['src', 'allowfullscreen'],
  'newsletter-signup-form': [],
  img: ['src', 'alt', 'srcset', 'sizes', 'width', 'height', 'class'],
  'styled-text': ['content', 'center', 'children', 'class'],
  block: ['class'],
  h1: ['class'],
  h2: ['class'],
  h3: ['class'],
  h4: ['class'],
  h5: ['class'],
  h6: ['class'],
  details: ['class'],
  summary: ['class'],
}

/** Convert directive elements into HTML elements with properties */
const visitor: Visitor<DirectiveNode> = (node, index, parent) => {
  // remove disallowed tags from the AST
  if (!Object.keys(allowedTags).includes(node.name) && parent) {
    parent.children.splice(index, 1)
    return
  }
  // Filter allowed attributes
  const attrWhitelist = allowedTags[node.name] || []
  const attributes = Object.fromEntries(
    Object.entries(node.attributes).filter(([key]) =>
      attrWhitelist.includes(key),
    ),
  )
  node.data = node.data || {}
  // build an HTML tag using the node's name property
  const hast = h(node.name, attributes)
  // assign the hast data to the node
  node.data.hName = hast.tagName
  node.data.hProperties = hast.properties
}

const plugin: Plugin = () => {
  return transformer
}

export default plugin
