import { makeStyles } from '@material-ui/core'

import { IAsset, ILink, isAsset } from '../../lib/contentful/types'

type TWidth = {
  viewportMax: number
  width: number
  allowScaling: boolean
}

const IMAGE_WIDTHS = [300, 480, 600, 800, 1024, 1200, 1920, 2550]
const DEFAULT_WIDTHS: TWidth[] = IMAGE_WIDTHS.map((width) => ({
  viewportMax: width,
  width,
  allowScaling: width >= 800,
}))

const getCSSImageUrl = (url: string) => `url(${url})`

const useStyles = makeStyles((_theme) => {
  const backgroundImageStyles = ({
    imageUrl,
    widths,
  }: {
    imageUrl: string
    widths: TWidth[]
  }) => {
    if (!imageUrl) return {}
    const quality = 70

    const styles: Record<string, string | Record<string, string>> = {
      backgroundImage: getCSSImageUrl(imageUrl),
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    }

    let lastViewportMax = 0
    for (const widthVal of widths) {
      const includeHDPI = widthVal.viewportMax / 2 >= 800
      const mq = [
        [
          `(min-width: ${lastViewportMax + 1}px)`,
          `(max-width: ${widthVal.viewportMax}px)`,
        ],
        [
          `(min-width: ${Math.round(lastViewportMax / 2) + 1}px)`,
          `(max-width: ${Math.round(widthVal.viewportMax / 2)}px)`,
          `(min-resolution: 150dpi)`,
        ],
      ]
        .map((a) => a.join(' and '))
        .slice(0, includeHDPI ? 2 : 1)
      const prop = `@media ${mq.join(', ')}`
      styles[prop] = {
        backgroundImage: getCSSImageUrl(
          `${imageUrl}?w=${widthVal.width}&q=${quality}&fl=progressive&fm=jpg`,
        ),
      }
      lastViewportMax = widthVal.viewportMax
    }

    return styles
  }

  return { backgroundImageStyles }
})

type TUseBackgroundImageOpts = { widths: TWidth[]; scale: number }
const defaultProps: TUseBackgroundImageOpts = {
  widths: DEFAULT_WIDTHS,
  scale: 100,
}

export function useBackgroundImage(
  image: IAsset | ILink<'Asset'> | string | undefined,
  props?: Partial<TUseBackgroundImageOpts>,
): string | undefined {
  const { widths, scale } = {
    ...defaultProps,
    ...props,
  } as TUseBackgroundImageOpts
  const computedWidths = widths.map((W) => ({
    ...W,
    width: W.allowScaling ? Math.round((W.width * scale) / 100) : W.width,
  }))
  const imageUrl =
    typeof image === 'string'
      ? image
      : image && isAsset(image)
      ? image.fields.file.url || ''
      : ''
  const classes = useStyles({ imageUrl, widths: computedWidths })
  if (!imageUrl) return
  return classes.backgroundImageStyles
}
