import {
  Typography,
  Link as MUILink,
  makeStyles,
  Grid,
  Button,
} from '@material-ui/core'
import { Variant } from '@material-ui/core/styles/createTypography'
import { Launch, Link as LinkIcon } from '@material-ui/icons'
import React from 'react'
import { Components, ReactMarkdownProps } from 'react-markdown/src/ast-to-react'

import ButtonLink from '@aletheia/common/components/ButtonLink'
import Link from '@aletheia/common/components/Link'
import ResponsiveEmbed from '@aletheia/common/components/ResponsiveEmbed'
import { isHashLink, isRelative } from '@aletheia/common/utils/links'

/** Wrap `NormalComponent` (the type of a React Markdown renderer) with a generic that can specify additional props */
type Renderer<T extends Record<string, unknown> = Record<string, unknown>> = (
  props: ReactMarkdownProps & T,
) => React.ReactNode

const Paragraph: Renderer = ({ children }) => (
  <Typography gutterBottom>{children}</Typography>
)

const Div: Renderer = ({ children, ...props }) => (
  <div {...parseProps(props)}>{children}</div>
)

const useHeadingStyles = makeStyles((theme) => ({
  root: {
    '& $anchorLink': {
      visibility: 'hidden',
    },
    '&:hover $anchorLink': {
      visibility: 'visible',
    },
    '&:before': {
      width: 'calc(1em + 16px)',
      height: 'calc(0.75em)',
      content: '""',
      display: 'block',
      position: 'absolute',
      marginLeft: 'calc(-1em - 16px)',
      marginTop: '0.25em',
    },
  },
  anchorLink: {
    fontSize: '1em',
    position: 'relative',
    display: 'inline-block',
    width: '1em',
    marginLeft: '-1.4em',
    marginRight: '0.4em',
    color: theme.palette.grey[300],
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}))

const Heading: Renderer<{ level: number; id?: string }> = ({
  level,
  children,
  node,
  id,
  ...props
}) => {
  const classes = useHeadingStyles()
  const variant = level < 6 ? (`h${level + 1}` as Variant) : 'body1'
  const component = `h${level}` as React.ElementType
  return (
    <Typography
      gutterBottom
      variant={variant}
      component={component}
      className={classes.root}
      id={id}
      {...props}
    >
      {id && (
        <MUILink
          href={`#${id}`}
          aria-hidden="true"
          className={classes.anchorLink}
        >
          <LinkIcon />
        </MUILink>
      )}
      {children}
    </Typography>
  )
}

const SimpleHeading: Renderer<{ level: number; id?: string }> = ({
  level,
  children,
  node,
  id,
  ...props
}) => {
  const variant = level < 6 ? (`h${level - 1}` as Variant) : 'body1'
  const component = `h${level - 1}` as React.ElementType
  return (
    <Typography variant={variant} component={component} id={id} {...props}>
      {children}
    </Typography>
  )
}

const ListItem: Renderer<{ ordered: boolean }> = ({
  node,
  children,
  ordered,
  ...props
}) => (
  <Typography component="li" {...props}>
    {children}
  </Typography>
)

const useThematicBreakStyles = makeStyles((theme) => ({
  root: {
    border: 'none',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    textAlign: 'center',
    height: 'initial',
    '&::before': {
      content: '"* * *"',
    },
  },
}))
const ThematicBreak: Renderer = () => {
  const classes = useThematicBreakStyles()
  return <hr className={classes.root} />
}

const LinkRenderer: Renderer<{ href: string }> = ({
  href,
  children,
  node,
  ...props
}) => {
  if (isRelative(href)) {
    return (
      <Link href={href} {...props}>
        {children}
      </Link>
    )
  }
  if (isHashLink(href)) {
    return (
      <MUILink href={href} {...props}>
        {children}
      </MUILink>
    )
  }
  // external links
  return (
    <MUILink href={href} target="_blank" rel="noreferrer" {...props}>
      {children}
    </MUILink>
  )
}

const useButtonStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
}))

const ButtonRenderer: Renderer<{ href: string }> = ({
  node,
  href,
  children,
  ...props
}) => {
  const classes = useButtonStyles()
  if (isRelative(href)) {
    return (
      <ButtonLink href={href} className={classes.root} {...parseProps(props)}>
        {children}
      </ButtonLink>
    )
  }
  if (isHashLink(href)) {
    return (
      <Button href={href} className={classes.root} {...parseProps(props)}>
        {children}
      </Button>
    )
  }
  // external links
  return (
    <Button
      href={href}
      className={classes.root}
      target="_blank"
      rel="noreferrer"
      {...parseProps(props)}
    >
      {children} <Launch />
    </Button>
  )
}

const useImageStyles = makeStyles((_theme) => ({
  root: {
    maxWidth: '100%',
    display: 'block',
    margin: '0 auto',
  },
}))

const Image: Renderer<{ src: string; title: string }> = ({
  node,
  children,
  ...props
}) => {
  const classes = useImageStyles()
  return <img className={classes.root} {...parseProps(props)} />
}

const useGridContainerStyles = makeStyles((_theme) => ({
  root: {
    marginBottom: '1em',
  },
}))

const GridContainer: Renderer = ({ node, children, ...props }) => {
  const classes = useGridContainerStyles()
  return (
    <Grid container className={classes.root} {...parseProps(props)}>
      {children}
    </Grid>
  )
}

const GridItem: Renderer = ({ node, children, ...props }) => {
  return (
    <Grid item {...parseProps(props)}>
      {children}
    </Grid>
  )
}

const Embed: Renderer = ({ node, children, ...props }) => {
  const { src, height = undefined } = parseProps(props)
  return <ResponsiveEmbed src={src as string} height={height as string | ''} />
}

const renderers: Components & {
  block: Renderer
  'grid-container': Renderer
  'grid-item': Renderer
} = {
  p: Paragraph as Components['p'],
  h1: Heading,
  h2: Heading,
  h3: Heading,
  h4: Heading,
  h5: Heading,
  h6: Heading,
  li: ListItem,
  hr: ThematicBreak as Components['hr'],
  a: LinkRenderer as Components['a'],
  button: ButtonRenderer as Components['button'],
  img: Image as Components['img'],
  'grid-container': GridContainer,
  'grid-item': GridItem,
  embed: Embed as Components['embed'],
  block: Div,
}

export const simpleHeadingsRenderers = Object.assign({}, renderers, {
  h1: SimpleHeading,
  h2: SimpleHeading,
  h3: SimpleHeading,
  h4: SimpleHeading,
  h5: SimpleHeading,
  h6: SimpleHeading,
})

export default renderers

function parseProps(props: Partial<ReactMarkdownProps>) {
  return Object.fromEntries(
    Object.entries(props).map(([key, value]) => {
      // parse numbers
      if (typeof value === 'string') {
        if (/^\d+(\.\d+)?$/.test(value)) {
          return [key, parseFloat(value)]
        }
        // cast true.
        // If the prop is an empty string, treat it as setting this key to true
        if (value === 'true' || value === '') {
          return [key, true]
        }
        // cast false
        if (value === 'false') {
          return [key, false]
        }
      }
      // other values
      return [key, value]
    }),
  )
}
